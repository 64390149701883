<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mhome-head">
				<div class="prev" @click="$router.back()"><i class="el-icon-arrow-left"></i></div>
				<div class="action">
					<span @click="handleAddressAction">{{actionType?$t('mobile.actions.finish'):$t('mobile.actions.manage')}}</span>
				</div>
			</div>
			<div class="mhome-main">
				<div class="mhome-address" :class="{edit:actionType}">
					<div class="item" v-for="(item,index) in addressList" :key="index">
						<div class="item-main">
							<!-- <div class="item-icon"></div> -->
							<div class="item-box">
								<div class="user-info">
									<span class="name">{{item.consignee}}</span>
									<span class="tel">{{item.phone_mob}}</span>
									<span class="default" v-if="item.addr_id == defaultAddress">{{$t('mobile.tags.default')}}</span>
								</div>
								<div class="address">{{item.region_name+item.address}}</div>
							</div>
							<div class="item-edit" @click="handleEditAddress(item.addr_id)"><i class="el-icon-edit"></i></div>
						</div>
						<div class="item-bottom" v-if="actionType">
							<el-radio v-model="defaultAddress" :label="item.addr_id" @change="handleDefault(item)">{{$t('mobile.checkbox.defaultAddress')}}</el-radio>
							<span class="delete" @click="handleDelete(item.addr_id)">{{$t('mobile.actions.delete')}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="mhome-block"></div>
			<div class="mhome-bottom">
				<div class="mfooter-nav">
					<div class="btn" @click="handleAddAddress">{{'+'+$t('mobile.actions.addAddress')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getAddressList, uploadAddress, deleteAddress } from '@/api/mobile/address';
	export default {
		data() {
			return {
				addressList: [],
				actionType: false,
				defaultAddress: ''
			}
		},
		mounted() {
			this.getAddressList();
		},
		methods: {
			getAddressList() {
				getAddressList({uuid: this.$store.getters.uuid}).then(response => {
					this.addressList = response.data.lise || [];
					this.addressList.forEach(item => {
						if(item.is_default == 1) {
							this.defaultAddress = item.addr_id
						}
					})
				})
			},
			handleEditAddress(id){
				this.$router.push({
					name: "mAddressInfo",
					query: {
						...this.$route.query,
						edit_id: id
					}
				});
			},
			handleAddAddress(){
				this.$router.push({
					name: "mAddressInfo",
					query: this.$route.query
				});
			},
			handleAddressAction(){
				this.actionType = this.actionType ? false : true;
			},
			handleDelete(id){
				this.$confirm(this.$t('mobile.confirm.deleteAddress'),'',{
					confirmButtonText: this.$t('mobile.actions.delete'),
					cancelButtonText: this.$t('mobile.actions.cancel'),
					center: true,
					customClass: 'wap-message-confirm',
				}).then(() => {
					deleteAddress({addr_id: id}).then(() => {
						this.getAddressList();
					})
				}).catch(() => {
					this.$message({
						message: this.$t('mobile.errorMsg.delete'),
						customClass: 'wap-message',
						type: 'error'
					});
				})
			},
			handleDefault(item) {
				item.is_default = 1
				uploadAddress(item).then(() => {
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mhome-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		// padding: 0.256rem 0.384rem;
		height: 1.28rem;
		line-height: 1.28rem;
		background-color: #FFFFFF;
		.prev {
			width: 1.28rem;
			height: 1.28rem;
			line-height: 1.28rem;
			font-size: 0.64rem;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			color: #777777;
		}
		.action {
			color: #ff5000;
			font-size: 0.384rem;
			margin-right: 0.384rem;
		}
	}

	.mhome-main {
		padding: 0.32rem 0.192rem;
	}

	.mhome-address {
		overflow: hidden;
		border-radius: 0.256rem;

		.item {
			padding: 0 0.48rem;
			background-color: #FFFFFF;
			.item-main {
				display: flex;
				align-items: center;
				padding: 0.32rem 0;
				.item-box {
					flex: 1;
					width: 0;
					margin-right: 0.864rem;
				
					.user-info {
						line-height: 0.768rem;
						margin-bottom: 0.128rem;
				
						span {
							margin-right: 0.192rem;
						}
				
						.name {
							font-size: 0.48rem;
							font-weight: bold;
						}
				
						.tel {
							color: #999999;
							font-size: 0.416rem;
						}
				
						.default {
							color: #ff5000;
							font-size: 0.352rem;
							line-height: 1em;
							display: inline-block;
							padding: 0.096rem;
							border-radius: 0.096rem;
							border: 1px solid #ff5000;
						}
					}
				
					.address {
						font-size: 0.384rem;
						line-height: 0.544rem;
					}
				}
				
				.item-edit {
					color: #999999;
					font-size: 0.576rem;
				}
			}
			.item-bottom {
				display: flex;
				align-items: center;
				padding: 0.192rem 0;
				justify-content: space-between;
				border-top: 1px solid #E5E5E5;
				.delete {
					color: #999999;
					font-size: 0.416rem;
				}
				::v-deep .el-radio {
					.el-radio__inner {
						width: 0.512rem;
						height: 0.512rem;
						border-radius: 50%;
					}
					
					.el-radio__label {
						color: #333333;
						font-size: 0.416rem;
						line-height: 0.512rem;
						vertical-align: middle;
					}
				
					.el-radio__inner::after {
						top: 0.064rem;
						left: 0.16rem;
						width: 0.096rem;
						height: 0.224rem;
						border-radius: 0;
						background-color: transparent;
						position: absolute;
						transform: rotate(45deg) scaleY(0);
						border-right: 0.032rem solid #FFFFFF;
						border-bottom: 0.032rem solid #FFFFFF;
					}
				
					.el-radio__input.is-checked {
						.el-radio__inner {
							background-color: #f25528;
							border-color: #f25528;
							&:after {
								transform: rotate(45deg) scaleY(1);
							}
						}
					}
				}
			}
		}
	}
	.mhome-address.edit {
		.item {
			margin-bottom: 0.32rem;
			border-radius: 0.256rem;
		}
	}

	.mhome-block {
		height: 1.664rem;
	}

	.mfooter-nav {
		padding: 0.192rem 0.384rem;
		background-color: #FFFFFF;
		box-shadow: 0 -1px 1px #E5E5E5;

		.btn {
			height: 1.28rem;
			line-height: 1.28rem;
			text-align: center;
			font-size: 0.48rem;
			color: #FFFFFF;
			border-radius: 0.64rem;
			background: linear-gradient(90deg, #ee7e32, #f25528);
		}
	}
</style>
